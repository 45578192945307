import { cloneDeep } from "lodash";
/**
 * 获取制定级别的场所数量
 * @param {*} areaCode
 * @param {*} level
 * @param {*} placeList
 * @param {*} list
 */
function queryChildPlaceForLevel(areaCode, level = 5, placeList, list = []) {
  placeList.forEach(item => {
    if (item.areaCode == areaCode && item.level >= level) {
      list.push(item);
    } else {
      if (item.parentCode == areaCode) {
        queryChildPlaceForLevel(item.areaCode, level, placeList, list);
      }
    }
  });
  return list;
}

export function queryPlaceDeviceAndPerson() {
  const { qj, znxj, zpj, db, clzpj } = Dict.map;
  const cameraTypes = []
  Dict.map.deviceType.filter(v => v.value !== "-1" && v.value !== "100605").map(v => {
    let item = v.value.split(',')
    if (item.length > 1) {
      cameraTypes.push(item[0])
      cameraTypes.push(item[1])
    } else {
      cameraTypes.push(v.value)
    }
    return cameraTypes
  });
  return Promise.all([
    Utils.catchPromise(Service.place.getPlacesByHasDevice()), // 获取所有有设备的场所
    Utils.catchPromise(Service.place.countPersonGroupByPid()), //
    Utils.catchPromise(Service.device.countPlaceDeviceStatus({ deviceTypes: [+qj.value, +znxj.value, +zpj.value, +clzpj.value, +db.value] })), //3
    Utils.catchPromise(Service.device.countPlaceDeviceStatus({ deviceTypes: [+qj.value, +znxj.value, +zpj.value, +clzpj.value] })), //4
    Utils.catchPromise(Service.device.countPlaceDeviceStatus({ deviceTypes:cameraTypes,ignoreUserRestrictions: true })) //5
  ]).then(res => {
    let placeList = res[0] ? res[0].data || [] : [];
    let placePerson = res[1] ? res[1].data || [] : [];
    let placeDevice = res[2] ? res[2].data || [] : [];
    let placeCamera = res[3] ? res[3].data || [] : [];
    let placeAllDevice = res[4] ? res[4].data || [] : [];

    //TODO 场所设备，摄像机，人员数量
    for (let ii = 0, ll = placeList.length; ii < ll; ii++) {
      let item = placeList[ii];
      let placeCountDevice = placeDevice.find(v => v.placeId == item.id);
      let placeCountCamera = placeCamera.find(v => v.placeId == item.id);
      let placeAllCountDevice = placeAllDevice.find(v => v.placeId == item.id);
      let placeCountPerson = placePerson.find(v => v.pid == item.id);
      item.deviceCount = {
        count: placeCountDevice ? placeCountDevice.totalCount : 0,
        onlineCount: placeCountDevice ? placeCountDevice.onlineCount : 0
      };

      item.cameraCount = {
        count: placeCountCamera ? placeCountCamera.totalCount : 0,
        onlineCount: placeCountCamera ? placeCountCamera.onlineCount : 0
      };
      item.deviceAllCount = {
        count: placeAllCountDevice ? placeAllCountDevice.totalCount : 0,
        onlineCount: placeAllCountDevice ? placeAllCountDevice.onlineCount : 0
      };
      placeCountPerson && (item.personCount = placeCountPerson.count);
      let childs = queryChildPlaceForLevel(item.areaCode, 5, placeList, []);
      item.childrenPlaceCount = childs.length;
    }
    BaseStore.place.setPlaceList(placeList);
  });
}

export function computedPlaceDeviceCount(list, placeDevice = [], placePerson = []) {
  const placeList = cloneDeep(list);
  for (let ii = 0, ll = placeList.length; ii < ll; ii++) {
    let item = placeList[ii];
    let placeCountDevice = placeDevice.find(v => v.placeId == item.placeId || v.placeId == item.id);
    let placeCountPerson = placePerson.find(v => v.pid == item.id);
    item.personCount = placeCountPerson ? placeCountPerson.count : 0;
    item.deviceCount = {
      count: placeCountDevice ? placeCountDevice.totalCount : 0,
      onlineCount: placeCountDevice ? placeCountDevice.onlineCount : 0
    };
    let childs = queryChildPlaceForLevel(item.areaCode, 5, placeList, []);
    item.childrenPlaceCount = childs.length;
  }
  return placeList;
}
