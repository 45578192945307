import React from "react";
import { message, Switch } from "antd";
import "../style/set-point-map.scss";

const { MakerPoints, providerMap, mapContext,MapResetTools } = LMap;
const IconFont = Loader.loadBaseComponent("IconFont");
const SelectPoi = Loader.loadBusinessComponent("SelectPoi");

@providerMap("set-point-map")
@mapContext.map
class SetPointMap extends React.Component {
  constructor(props) {
    super(props);
    this.init = false;
    this.markerPoint = null;
    this.mapSearch = null;
    this.canClickSet = false;
    this.areaCircle = null;
    this.polygon = null;
  }
  componentDidMount() {
    this.props.mapRef && this.props.mapRef(this);
    this.createPolyline();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.point !== this.props.point) {
      this.setState({
        address: null,
        resultList: [],
        selectPoi: null,
        position: []
      });
      this.markerPoint.removeAllMarker();
      this.createMarker(nextProps.point);
    }
  }

  componentDidUpdate() {
    if (!this.init) {
      this.init = true;
      const { mapMethods } = this.props;
      mapMethods.on("click", this.clickMap);
    }
  }
  componentWillUnmount() {
    this.init = null;
    this.markerPoint = null;
    this.mapSearch = null;
    this.canClickSet = null;
    this.areaCircle = null;
    this.polygon = null;
  }

  clickMap = event => {
    const { dragenable = true } = this.props;
    if (this.canClickSet) {
      dragenable &&
        this.markerDrage(null, event, [event.lnglat.lng, event.lnglat.lat]);
    }
  };

  setClickPopint = checkd => {
    this.canClickSet = checkd;
    this.forceUpdate();
  };

  /**点位拖拽 */
  markerDrage = (data, event, position) => {
    this.onSelectPoi(position);
    this.createArea(position);
    // this.mapSearch
    //   .searchAddressForPosition(position)
    //   .then(address => {
    //     this.mapSearch.searchPois(address.name).then(() => {
    //       this.props.onChangePoint &&
    //         this.props.onChangePoint({ position, point, ...address });
    //     });
    //   })
    //   .catch(this.catchSearch);
  };

  /**
   * @desc 绘制场所圆形区域
   * @param {*} center
   */
  createArea(center) {
    const { clickArea, map } = this.props;
    this.areaCircle && map.remove(this.areaCircle);
    this.areaCircle = new AMap.Circle({
      map,
      center, // 圆心位置
      radius: 100, // 圆半径
      fillColor: "#ffaa00", // 圆形填充颜色
      fillOpacity: 0.2,
      strokeColor: "#ff8800", // 描边颜色
      strokeWeight: 2, // 描边宽度
      strokeStyle: "solid"
    });
    this.areaCircle.on("click", event => {
      clickArea && clickArea(event.target.getExtData());
    });
    this.areaCircle.on("mouseover", event => {
      event.target.setOptions({ fillOpacity: 0.4 });
    });
    this.areaCircle.on("mouseout", event => {
      event.target.setOptions({ fillOpacity: 0.2 });
    });
  }

  clearPolyline(){
    const { map } = this.props;
    this.polygon && map.remove(this.polygon);
  }

  /**
   * @desc 绘制场所边界
   */
  createPolyline() {
    const { map, polylineData, selectPlaceId } = this.props;
    this.polygon && map.remove(this.polygon);
    if (polylineData.polyline) {
      let path;
      try {
        path = JSON.parse(polylineData.polyline);
      } catch (e) {
        console.warn("格式化场所边界出错", polylineData.polyline);
      }
      if (!path) {
        return false;
      }
      this.polygon = new AMap.Polygon({
        map,
        zIndex: 100,
        strokeWeight: 2,
        path,
        fillOpacity: 0.1,
        fillColor: "#44AAFF",
        strokeColor: "#2299FF",
        strokeStyle: "dashed"
      });
      this.polygon.setExtData({
        id: polylineData.id,
        path
      });
    }else{
      if(selectPlaceId){
        // message.warn('当前场所无边界',2)
      }
    }
    return this.polygon;
  }

  catchSearch = error => {
    message.warn("未知的位置！");
  };
  changeSearch = address => {
    this.searchPois(address, false);
  };

  createMarker = (point, isSearch=false) => {
    const { polylineData } = this.props;
    if (!point || !point.latitude || !point.longitude) {
      return false;
    }
    setTimeout(() => {
      this.markerPoint && this.markerPoint.createMarker(point, {
        draggable: true,
        dragend: this.markerDrage,
        click: this.markerClick,
        label:`<div><p>设备名称：${point.deviceName}</p><p>所属场所：${polylineData.areaName || point.areaName || '暂无'}</p></div>`
      });
      !isSearch && this.props.mapMethods.setFitView();
    }, 10);
  };

  markerClick = e => {
    const { markerClick } = this.props;
    markerClick && markerClick(e);
  };

  initMarkerPoint = markerPoint => {
    const { point } = this.props;
    this.markerPoint = markerPoint;
    this.createMarker(point);
  };
  initMapSearch = mapSearch => {
    this.mapSearch = mapSearch;
  };

  // searchInfo: 搜索列表点击时的信息
  onSelectPoi = (position, searchInfo, isSearch) => {
    let { point, onChangePoint } = this.props;
    let newPoint = { ...point };
    if (searchInfo) {
      newPoint.name = searchInfo.name;
      newPoint.address = searchInfo.address;
    }
    const [longitude, latitude] = position;
    newPoint.longitude = longitude;
    newPoint.latitude = latitude;
    this.createMarker(newPoint, isSearch);
    onChangePoint && onChangePoint({ point: newPoint, position }, isSearch);
  };

  mapReset = () => {
    this.props.map.setFitView();
  };
  mapZoom = zoom => {
    this.props.map.setZoom(this.props.map.getZoom() + zoom);
  };
  render() {
    const { showSearch, showPoistList, children = null } = this.props;
    return (
      <React.Fragment>
        <MakerPoints init={this.initMarkerPoint} />
        {showSearch && (
          <SelectPoi map={this.props.mapMethods} getCenter={this.onSelectPoi} />
        )}
        {/* <MapSearch 
          init={this.initMapSearch} 
          onSelectPoi={this.onSelectPoi} 
          showSearch={showSearch} 
          showPoistList={showPoistList}
        /> */}
        <span className="set-point">
          <IconFont type="icon-S_Tree_Add" />{" "}
          点击获取点位{" "}
          <Switch
            size="small"
            defaultChecked={this.canClickSet}
            onChange={this.setClickPopint}
          />
        </span>
        <MapResetTools
          mapReset={this.mapReset}
          mapZoom={this.mapZoom}
        />
        {children}
      </React.Fragment>
    );
  }
}

export default SetPointMap;
