import React from 'react';
import { inject } from 'mobx-react';
import { Checkbox } from "antd";
const ItemTitleCon = Loader.loadBusinessComponent("ItemTitleCon");
const { MakerPoints, MapComponent, ClusterMarker, MapResetTools } = LMap;
const { mj, zj, wifi,placeTypeWithIcon } = Dict.map;
const CheckboxGroup = Checkbox.Group;


@inject('device')
class SimpleMap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.map = null;
    this.placeArea = [];
    this.circleMarker = null;
    this.centerMarker = null;
    this.markerRadius = null
    this.placeMarkers=[];
    this.state = {
      center: [0, 0],
      key: Math.random()
    };
  }
  initMap = map => {
    this.map = map;
  };

  componentWillUnmount() {
    this.placeArea = [];
    this.circleMarker = null;
    this.clusterMarker = null;
    this.placeMarkers=[]
  }
  markerClick(data, options = {}) {
    this.props.markerClick && this.props.markerClick(data.cid);
  }
  initMarkerPoint = markerPoint => {
    this.markerPoint = markerPoint;
    this.initOverlayers(this.props);
  };
  componentWillReceiveProps(props) {
    if (props.id !== this.props.id && this.map) {
      this.initOverlayers(props);
    }
  }

  initOverlayers(props) {
    this.removeOverlayers();
    const { center, zoom = 11, polyline, point,points } = props;
    try {
      this.createOverlayers({ point, polyline });
    } catch (e) {
      console.error('创建地图覆盖物出错', e);
    }
    const newOverlayers = [this.circleMarker, ...this.placeArea, this.centerMarker, this.placeMarker, this.markerRadius].filter(v => !!v);
    if (center && center[0]) {
      this.map.setZoomAndCenter(zoom, center);
    }
    this.map.addOverlayers(newOverlayers);
    if (this.placeArea.length > 0) {
      this.map.setFitView(this.placeArea);
    }
  }

  removeOverlayers() {
    const overlayers = [this.circleMarker, ...this.placeArea, this.centerMarker, this.placeMarker, this.markerRadius].filter(v => !!v);
    this.map.removeOverlayers(overlayers);
    this.placeArea = [];
    this.circleMarker = null;
    this.markerRadius = null;
    this.centerMarker = null;
    this.placeMarker = null;
  }

  setValigeFitView = (path) => {
    let lngs = [],
      lats = [];

    lngs = path.map(d => d[0]);
    lats = path.map(d => d[1]);
    let lngMax = Math.max(...lngs);
    let lngMin = Math.min(...lngs);
    let latMax = Math.max(...lats);
    let latMin = Math.min(...lats);
    let bounds = new AMap.Bounds([lngMin, latMin], [lngMax, latMax]);
    return bounds;
  }

  getCenterPoint = (deviceList) => {
    let lngs = [],
    lats = [];
    deviceList.map(v => {
      if (v.longitude && v.latitude) {
        lngs.push(v.longitude)
        lats.push(v.latitude)
      }
    })
    let lngMax = Math.max(...lngs);
    let lngMin = Math.min(...lngs);
    let latMax = Math.max(...lats);
    let latMin = Math.min(...lats);
    return [(lngMax + lngMin) / 2, (latMax + latMin) / 2]
  }
  createOverlayers = ({ point, polyline }) => {
    let { centerRadius } = this.props;
    if (point && point.latitude && point.longitude && this.markerPoint) {
      this.markerRadius = this.markerPoint.getMapRadiusContent(point,500)
      this.centerMarker = this.markerPoint.createMarker(point, { w: 30, h: 40, offset: [-15, -40] }, { isMut: true }, 'isCenter');
      this.centerMarker.setzIndex(102);
      if (centerRadius) {
        this.circleMarker = new AMap.Circle({
          center: new AMap.LngLat(point.longitude, point.latitude), // 圆心位置
          zIndex: 99,
          strokeWeight: 2,
          fillOpacity: 0.1,
          fillColor: '#FFAA00',
          strokeColor: '#FFAA00',
          // strokeStyle: 'dashed',
          radius: centerRadius || 500 // 圆半径
        });
      }
    }
    if (polyline) {
      let allPoly=[]
      polyline.split('|').map(polylineItem => {
        let path = polylineItem.split(';').map(v => v.split(','));
        allPoly = allPoly.concat(path)
        this.placeArea.push(
          new AMap.Polygon({
            zIndex: 100,
            strokeWeight: 2,
            path: path,
            fillOpacity: 0.1,
            fillColor: '#44AAFF',
            strokeColor: '#2299FF',
            strokeStyle: 'dashed'
          })
        ) 
      })
      if (allPoly.length){
        this.map.setBounds(this.setValigeFitView(allPoly))
      }
    }
  };
  reset = () => {
    const { center, points, zoom = 17 } = this.props;
    if (center && center[0]) {
      this.map && this.map.setZoomAndCenter(zoom, center);
    } else {
      this.map && points.length && this.map.setZoomAndCenter(zoom, this.getCenterPoint(points));
    }
  };
  onChange = checkedValues => {
    let {placePoints}= this.props
    let showTagsPlaces = []
    if (checkedValues.length) {
      showTagsPlaces = placePoints.filter(v => {
        return v.placeTags.filter(x => {
          return checkedValues.indexOf(x) >= 0;
        }).length > 0;
      });
    }
    this.map.removeOverlayers(this.placeMarkers);
    this.placeMarkers=[]
    showTagsPlaces.forEach(v => {
      v.deviceName = v.areaName
      let pio = v.center.split(',')
      v.longitude = pio[0]
      v.latitude = pio[1]
      // if (v.longitude && v.latitude){
        v.placeTags.forEach(j => {
          v.placeTag=j
          if (checkedValues.indexOf(j)>-1){
            let item = placeTypeWithIcon.find(item => item.value === j)
            if(item){
              this.placeMarkers.push(this.markerPoint.createMarker(v, {}, {
                isMut: true,
                // color:'red',
              },'isPlace'))
            }
          }
        })
      // }
    })
    this.map.addOverlayers(this.placeMarkers)

  };
  render() {
    const { device, points,center ,zoom ,placeTypeNum} = this.props;
    if (!(center && center[0]) && points.length) {
      this.map && this.map.setZoomAndCenter(zoom, this.getCenterPoint(points));
    }
    return (
      <MapComponent initMap={this.initMap} mapConfig={{scrollWheel:false}}>
        <MakerPoints init={this.initMarkerPoint} />
        <ClusterMarker
          options={{
            hoverContent:<div></div>,
            click: this.markerClick.bind(this)
          }}
          filterResource={false}
          excludeTypes={[zj, wifi]}
          points={points ? points : device.deviceArray}
        />
        <MapResetTools mapReset={this.reset} />
        {
          placeTypeNum&&
          <CheckboxGroup onChange={this.onChange}>
            {Object.values(placeTypeNum).find(v => v > 0) && (
              <div className="place-device-resources">
                {Object.keys(placeTypeNum).map(item => {
                  let value = placeTypeNum[item];
                  if (value) {
                    return (
                      <Checkbox value={item}>
                        <ItemTitleCon
                          icon={
                            `icon-${placeTypeWithIcon.find(v => v.value == item) &&
                            placeTypeWithIcon.find(v => v.value == item).icon}`
                          }
                          title={
                            placeTypeWithIcon.find(v => v.value == item) &&
                            placeTypeWithIcon.find(v => v.value == item).label
                          }
                          con={value}
                        />
                      </Checkbox>
                    );
                  }
                })}
              </div>
            )}
          </CheckboxGroup>
        }
        {/* {children && React.Children.map(children, child => (React.isValidElement(child) ? React.cloneElement(child, { clusterMarker: this.clusterMarker }) : child))} */}
      </MapComponent>
    );
  }
}

export default SimpleMap;
