import React from "react";
import VideoView from "./VideoView";
import { inject } from "mobx-react";
import { cloneDeep } from "lodash";
import { message } from 'antd';

const { MapComponent, ClusterMarker, MapResetTools, InfoWindow } = LMap;
const { mj, wifi, db } = Dict.map;

@inject("device")
class ClusterMap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.clusterMarker = null;
    this.infoWindow = null;
    this.map = null;
    this.state = {
      info: null, // 视频fileData
      center: [0, 0],
      visible: false,
      key: Math.random()
    };
  }
  initMap = map => {
    this.map = map;
  };
  initClusterMarker = clusterMarker => {
    this.clusterMarker = clusterMarker;
    this.forceUpdate();
  };
  initInfoWindow = infoWindow => {
    this.infoWindow = infoWindow;
  };
  componentWillUnmount() {
    this.clusterMarker = null;
    this.infoWindow = null;
  }
  markerClick(data, options = {}) {
    const { canPlay = true, goPage, closeCustomInfoWindow } = this.props;
    closeCustomInfoWindow && closeCustomInfoWindow();

    const hasLiving = !!BaseStore.menu.getInfoByName("realVideo");

    if (!hasLiving) {
      if (options.isLiving === undefined || options.isLiving) {
        // console.log('无直播权限');
        return;
      }
      if (!data.longitude || !data.latitude) {
        // console.log('无经纬度 无直播权限');
        return;
      }
    }

    if (!canPlay) {
      return false;
    }
    // if (data.deviceType * 1 !== 103401) {
    //   return message.warning('当前设备不支持看视频！');
    // }
    if (!data.longitude || !data.latitude) {
      const id = Utils.uuid();
      LM_DB.add("parameter", {
        id,
        data: { selectIds: [data.id] }
      }).then(() => {
        goPage({
          moduleName: "videoSurveillance",
          data: { pid: id, mapMode: false }
        });
      });
      return;
      // return message.warning('当前设备没有设置经纬度！');
    }
    options.isLiving = options.isLiving === undefined ? true : options.isLiving;
    this.setPlayMethods(options, data);
  }

  // 播放历史或实时流
  setPlayMethods = async (options, info) => {
    info = info ? info : cloneDeep(this.state.info);
    const { isLiving, startTime, endTime, isFrontEndSeek, percent } = options;
    const position = [info.longitude, info.latitude];
    if (!isLiving) {
      // 历史
      const { queryHistoryAddress } = this.props;
      let res;
      try {
        res = await queryHistoryAddress({ cid: info.cid, startTime, endTime });
      } catch (error) {
        return message.warn('获取视频失败');
      }
      info.isLiving = false;
      if(isFrontEndSeek && res) {
        info.historyList.fragments[0].file = res.fragments[0].file;
        info.seekPercent = percent;
      } else {
        info.historyList = res;
        info.seekPercent = undefined;
      }
    } else {
      info.isLiving = true;
      info.historyList = undefined;
      info.seekPercent = undefined;
      const { toggleTimeChoiseContent, queryRealTimeAddressMulti } = this.props;
      toggleTimeChoiseContent(false);
      // if(info.file) {
      // 已经保存过实时视频的地址，直接播放
      // 问题： 开流设置会失效
      // } else {
      const fileDatas = await queryRealTimeAddressMulti([info]);
      info.file = fileDatas[0].file;
      // }
    }
    this.setState({
      info,
      center: position,
      visible: true,
      key: Math.random()
    });
  };

  // 处理播放器上历史实时切换按钮
  handleVideoSwitch = (options, isHistory) => {
    if (!isHistory) {
      this.setPlayMethods(options);
    } else {
      this.props.handleHistoryVideo(options);
    }
  };

  handleRectSearch = status => {
    const { info } = this.state;
    this.setState({
      info: Object.assign({}, info, { rectSearchStatus: status })
    });
  };

  closeVideo() {
    this.setState({ visible: false, info: {} });
    this.props.closeVideo();
  }

  render() {
    const {
      device,
      children,
      points,
      hasVideo = true,
      hoverContent,
      markerMouseover,
      markerMouseout,
      hasDownload,
      hasScreenshot = true,
      communityLayer = false,
      hasRectSearch = false,
      mapConfig,
      noAutoUpdate = false,
      onInit,
      customInfoWindow,
      onFrontEndSeek,
    } = this.props;
    const { info, center, visible, key } = this.state;
    return (
      <MapComponent mapConfig={mapConfig} initMap={this.initMap}>
        <ClusterMarker
          options={{
            click: this.markerClick.bind(this),
            hoverContent,
            mouseover: markerMouseover,
            mouseout: markerMouseout
          }}
          filterResource={true}
          excludeTypes={communityLayer ? [wifi, db] : [mj, wifi]}
          points={points ? points : device.deviceArray}
          init={this.initClusterMarker}
          noAutoUpdate={noAutoUpdate}
          onInit={onInit}
        />
        {hasVideo && (
          customInfoWindow 
            ? customInfoWindow
            : <InfoWindow
                visible={visible}
                center={center}
                init={this.initInfoWindow}
                content={
                  visible ? (
                    <VideoView
                      key={key}
                      info={info}
                      closeVideo={() => this.closeVideo()}
                      setPlayMethods={this.handleVideoSwitch}
                      hasScreenshot={hasScreenshot}
                      hasDownload={hasDownload}
                      handleRectSearch={hasRectSearch && this.handleRectSearch}
                      onFrontEndSeek={onFrontEndSeek}
                    />
                  ) : null
                }
              />
        )}
        <MapResetTools />
        {children &&
          React.Children.map(children, child =>
            React.isValidElement(child) ? React.cloneElement(child, { clusterMarker: this.clusterMarker }) : child
          )}
      </MapComponent>
    );
  }
}

export default ClusterMap;
