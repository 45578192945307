import React from 'react';
import DrawTools from './DrawTools';
import { inject } from 'mobx-react';
import { Modal } from 'antd';
import * as _ from 'lodash';

import '../style/select-map.scss';

const confirm = Modal.confirm;
const DeviceList = Loader.loadBusinessComponent('ListComponent');
const { MouseTool, ClusterMarker, MapResetTools, mapContext, MapComponent } = LMap;

@inject('device')
@mapContext.map
class SelectMap extends React.Component {
  constructor(props) {
    super(props);
    this.clusterMarker = null;
    this.mouseTool = null;
    this.cursor = null;
    this.modifyList = [];
  }

  componentWillMount() {
    this.mapConfig = Utils.getUserCache('baselibMapConfig') || {};
  }

  componentWillUnmount() {
    this.cacheZoomAndCenter();
    this.clusterMarker = null;
    this.mouseTool = null;
    this.cursor = null;
  }

  cacheZoomAndCenter = () => {
    const center = this.map.getCenter();
    const zoom = this.map.getZoom();
    const storeValue = { center: [center.lng, center.lat], zoom };
    Utils.setUserCache('baselibMapConfig', storeValue);
  };

  initMap = map => {
    this.map = map;
  };

  initClusterMarker = clusterMarker => {
    this.clusterMarker = clusterMarker;
    this.forceUpdate();
  };

  initMouseTools = mouseTool => {
    let { clearDraws } = this.props;
    this.mouseTool = mouseTool;
    clearDraws && clearDraws(this.mouseTool);
  };

  startDrawRect() {
    this.cursor = this.map.getDefaultCursor();
    this.map.setDefaultCursor('crosshair');
    this.mouseTool.rectangle();
  }

  startDrawCircle() {
    this.cursor = this.map.getDefaultCursor();
    this.map.setDefaultCursor('crosshair');
    this.mouseTool.circle();
  }

  startDrawPolygon() {
    this.cursor = this.map.getDefaultCursor();
    this.map.setDefaultCursor('crosshair');
    this.mouseTool.polygon();
  }

  drawEnd = (path, isCircle) => {
    const { selectList = [] } = this.props;
    const formatList = this.getFormatList(selectList);
    const allPoints = this.clusterMarker.getAllPoints();
    this.mouseTool.close();
    this.map.setDefaultCursor(this.cursor);
    let points = [];
    if (isCircle) {
      points = this.map.computedPointsInCircle(allPoints, path.center, path.radius);
    } else {
      points = this.map.computedPointsInArea(allPoints, path);
    }
    const list = _.uniqBy([...points, ...formatList], 'id');
    this.modifyList = _.uniqBy(this.modifyList.concat(_.differenceBy(points, formatList, 'id')), 'id');
    this.props.onChange && this.props.onChange({ list });
  };

  clearDraw = () => {
    const { clearConfirm = false, selectList } = this.props;
    const formatList = this.getFormatList(selectList);
    const drawList = _.differenceBy(formatList, this.modifyList, 'id');
    if (!clearConfirm) {
      this.mouseTool.close(true);
      this.props.onChange && this.props.onChange({ list: drawList });
      return;
    }
    confirm({
      title: '是否确定清除选中设备?',
      onOk: () => {
        this.mouseTool.close(true);
        this.props.onChange && this.props.onChange({ list: drawList });
      }
    });
  };

  /**
   * 列表操作导致select改变
   */
  listChange = ({ list, changeAll, flag }) => {
    const { onChange, clearConfirm = false } = this.props;
    if (!changeAll) {
      onChange({ list });
      return;
    }
    if (clearConfirm) {
      !flag &&
        confirm({
          title: '是否确定清除所有选中设备?',
          onOk: () => {
            this.mouseTool.close(true);
            onChange({ list: [] });
          }
        });
    } else {
      !flag && (this.mouseTool.close(true), onChange({ list: [] }));
    }
  };

  getFormatList = selectList => {
    return selectList.map(v => (typeof v === 'string' ? (BaseStore.device.getDeviceByIdOrCid(v) || { id: v, cid: v, deviceName: v }) : v));
  }

  render() {
    const { device, selectList = [], points, title, noAutoUpdate } = this.props;
    const isWifi = !!BaseStore.menu.getInfoByName('wifiLibrary') || BaseStore.user.userInfo.userType != '100701';
    const formatList = this.getFormatList(selectList);
    return (
      <MapComponent className="map-select-layout" mapConfig={this.mapConfig} initMap={this.initMap}>
        <ClusterMarker
          points={points ? points : device.deviceList}
          init={this.initClusterMarker}
          noAutoUpdate={noAutoUpdate}
          excludeTypes={isWifi ? [] : [Dict.map.wifi]}
        />
        <DrawTools
          startDrawRect={this.startDrawRect.bind(this)}
          startDrawCircle={this.startDrawCircle.bind(this)}
          startDrawPolygon={this.startDrawPolygon.bind(this)}
          clearDraw={this.clearDraw}
        />
        <MouseTool init={this.initMouseTools} drawEnd={this.drawEnd} />
        {selectList.length > 0 && (
          <DeviceList
            className="device-list-layout"
            listData={formatList}
            hasTitle={true}
            hasClear={true}
            checkable={false}
            title={`${title || '已选摄像机'}(${selectList.length}个)`}
            onChange={this.listChange}
          />
        )}
        <MapResetTools />
        <style jsx="true">{`
          .map-select-layout .map-reset-tool {
            left: auto;
            right: ${selectList.length > 0 ? '330px' : '20px'};
            bottom: 20px;
          }
        `}</style>
      </MapComponent>
    );
  }
}

export default SelectMap;
