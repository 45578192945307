import getResourceTotalList from './resourceTotalList';
import { getAMapCameraIcon, getMapIndexContent ,getMapRadiusContent } from './cameraIconTools';
import { downloadVideo } from './downloadVideo';
import { getCameraTypeIcon } from './mapIcon';
import { queryOrganizationDevice, computedOrganizationDevice } from './organization';
import { queryPlaceDeviceAndPerson,computedPlaceDeviceCount } from './place';
import geo from './geo';
import format from './format';
import searchList from './searchList';
import tabContext from './tabContext'
import {queryUserDevice} from './device'

const Shared = {
  getAMapCameraIcon,
  getMapIndexContent,
  getMapRadiusContent,
  downloadVideo,
  getCameraTypeIcon,
  getResourceTotalList,
  queryOrganizationDevice,
  queryPlaceDeviceAndPerson,
  computedPlaceDeviceCount,
  format,
  geo,
  searchList,
  tabContext,
  queryUserDevice,
  computedOrganizationDevice
};

export default Shared;
