import { cloneDeep } from "lodash";
export async function queryOrganizationDevice() {
  const { qj, znxj, zpj, db, clzpj } = Dict.map;

  const orgResult = await Utils.catchPromise(Service.organization.queryChildOrganizationsById());
  let orgList = orgResult ? orgResult.data || [] : [];
  BaseStore.organization.setOrgList(orgList);
  return Promise.all([
    Utils.catchPromise(
      Service.device.countOrganizationDeviceStatus({ deviceTypes: [+qj.value, +znxj.value, +zpj.value, +clzpj.value, +db.value] })
    ), //1
    Utils.catchPromise(
      Service.device.countOrganizationDeviceStatus({ deviceTypes: [+qj.value, +znxj.value, +zpj.value, +clzpj.value] })
    ) //2
  ]).then(res => {
    let orgDevice = res[0] ? res[0].data || [] : [];
    let orgCamera = res[1] ? res[1].data || [] : [];
    //TODO 计算组织设备和摄像机数量
    for (let ii = 0, ll = orgList.length; ii < ll; ii++) {
      let item = orgList[ii];
      let orgsCountDevice = orgDevice.find(v => v.organizationId == item.id);
      let orgsCountCamera = orgCamera.find(v => v.organizationId == item.id);
      item.deviceCount = {
        count: orgsCountDevice ? orgsCountDevice.totalCount : 0,
        onlineCount: orgsCountDevice ? orgsCountDevice.onlineCount : 0
      };
      item.cameraCount = {
        count: orgsCountCamera ? orgsCountCamera.totalCount : 0,
        onlineCount: orgsCountCamera ? orgsCountCamera.onlineCount : 0
      };
    }
    BaseStore.organization.setOrgList(orgList);
  });
}

export function computedOrganizationDevice(list, orgDevice) {
  const orgList = cloneDeep(list);
  for (let ii = 0, ll = orgList.length; ii < ll; ii++) {
    let item = orgList[ii];
    let orgsCountDevice = orgDevice.find(v => v.organizationId == item.id);
    item.deviceCount = {
      count: orgsCountDevice ? orgsCountDevice.totalCount : 0,
      onlineCount: orgsCountDevice ? orgsCountDevice.onlineCount : 0
    };
  }
  return orgList;
}
