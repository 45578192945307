import React from "react";
import "src/libs/echarts-amap";
import "../style/person-map-flow.scss";

const HorizontalScrollLayout = Loader.loadBaseComponent("HorizontalScrollLayout");
const IconFont = Loader.loadBaseComponent("IconFont");
const MapResetTools = LMap.MapResetTools;

function getLineWidth(count) {
  if (count <= 5) {
    return 2;
  }
  if (count <= 10) {
    return 4;
  }
  if (count <= 50) {
    return 7;
  }
  if (count <= 100 || count > 100) {
    return 10;
  }
}

class PathSimplifier extends React.Component {
  constructor() {
    super();
    this.domRef = React.createRef();
    this.layoutRef = React.createRef();
    this.echart = null;
    this.markerIndex = Utils.getMakerIndex();
    this.data = [];
    this.graphData = [];
    this.links = [];
    this.linesData = [];
    this.markers = [];
    this.areaCircle = [];
    this.amap = null;
    this.places = [];
    this.placeMarkers = [];
    this.passDeviceList = [];
    this.timer = null;
    this.state = {
      currentIndex: 0
    };
  }
  componentDidMount() {
    this.echart = echarts.init(this.domRef.current);
    this.layoutRef.current.addEventListener("mousewheel", this.ctrlMosueWheel, { passive: false });
    this.updateGraph();
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
    this.layoutRef.current.removeEventListener("mousewheel", this.ctrlMosueWheel, { passive: false });
    this.echart.dispose();
    this.amap.clearMap();
    this.amap.destroy();
    this.domRef = null;
    this.echart = null;
    this.markerIndex = null;
    this.data = null;
    this.graphData = null;
    this.links = null;
    this.linesData = null;
    this.markers = null;
    this.placeMarkers = null;
    this.areaCircle = null;
    this.places = null;
    this.amap = null;
    this.passDeviceList = null;
  }

  createMapGraph(countData = [], placeData = []) {
    const { passDeviceList } = this.props;
    this.places = placeData.filter(v => v.center);
    this.data = countData;
    // setTimeout(() => {
      this.computerdData(passDeviceList);
      this.createMarker();
      this.updateGraph();
      this.createArea();
      this.forceUpdate();
      this.mapReset();
    // }, 100);
  }

  componentWillReceiveProps(nextRrops) {
    if (nextRrops.passDeviceList !== this.props.passDeviceList) {
      this.computerdData(nextRrops.passDeviceList);
    }
  }
  /**
   * 过滤空点位
   */
  filterTrackCount = trackCount => {
    let arr = trackCount.filter(v => !!v.position[0] && !!v.position[1]); // 过滤坐标为空的抓拍记录
    return arr;
  };

  /**
   * 创建抓拍设备点位
   */
  createMarker() {
    const markers = [].concat(this.markers, this.placeMarkers);
    markers.length > 0 && this.amap.remove(markers);
    this.markers = [];
    this.placeMarkers = [];
    // 过滤
    let filterGraph = this.filterTrackCount(this.graphData);
    this.markers = filterGraph.map(item => {
      const marker = this.createMarkerIcon(item);
      marker.on("click", event => {
        this.markerClick(event.target.getExtData());
      });
      return marker;
    });
    this.placeMarkers = this.places.map(item => {
      const marker = this.createMarkerIcon(item, true);
      marker.on("click", event => {
        this.markerPlaceClick(event.target.getExtData());
      });
      marker.on("mouseover", event => {
        const content = this.getPlaceMakerContent(item);
        event.target.setLabel({ content });
      });
      marker.on("mouseout", event => {
        event.target.setLabel({ content: "" });
      });
      return marker;
    });
    const newMarkers = [].concat(this.markers, this.placeMarkers);
    if (newMarkers.length > 0) {
      this.amap.add(newMarkers);
    }
  }

  createMarkerIcon(item, isPlace = false) {
    return new AMap.Marker({
      position: isPlace ? item.center.split(",") : item.position,
      offset: new AMap.Pixel(-12, -32),
      content: `<div class="${isPlace ? "map-place-marker" : "map-marker-index"}">${isPlace ? "" : item.index}</div>`,
      extData: item
    });
  }

  getPlaceMakerContent(item) {
    return `
    <div class="map-place-label" ><strong>${item.placeName}</strong><p>${item.days > 7 ? 30 : 7}天内共出现${
      item.days
    }天</p></div>`;
  }

  //点击常去地图标
  markerPlaceClick(item) {
    let index = this.places.find(v => v.placeId === item.placeId);
    this.props.changePlace && this.props.changePlace(index);
  }

  //更新常去地图标
  updatePlaceMarkers = current => {
    this.placeMarkers.forEach(item => {
      if (item.getExtData().placeId === current.placeId) {
        item.setContent('<div class="map-place-marker active"></div>');
      } else {
        item.setContent('<div class="map-place-marker"></div>');
      }
    });
  };

  /**
   * @desc 绘制场所圆形区域
   * @param {*} data
   */
  createArea() {
    const { clickPlace } = this.props;
    this.areaCircle.length > 0 && this.amap.remove(this.areaCircle);
    this.areaCircle = [];
    if (!Array.isArray(this.places) || this.places.length === 0) {
      return;
    }
    this.areaCircle = this.places.map(v => {
      const circle = this.createAreaPath(v, !v.polyline);
      circle.on("click", event => {
        clickPlace && clickPlace(event.target.getExtData());
      });
      circle.on("mouseover", event => {
        event.target.setOptions({ fillOpacity: 0.4 });
      });
      circle.on("mouseout", event => {
        event.target.setOptions({ fillOpacity: 0.2 });
      });
      return circle;
    });
    if (this.areaCircle.length > 0) {
      this.amap.add(this.areaCircle);
    }
  }

  createAreaPath(v, isCricle = false) {
    if (isCricle) {
      return new AMap.Circle({
        center: v.center ? v.center.split(",") : v.position, // 圆心位置
        zIndex: 100,
        radius: 100, // 圆半径
        fillColor: "#44aaff", // 圆形填充颜色
        fillOpacity: 0.2,
        strokeColor: "#2299ff", // 描边颜色
        strokeWeight: 2, // 描边宽度
        strokeStyle: "dashed",
        extData: v
      });
    } else {
      let path = JSON.parse(v.polyline);
      return new AMap.Polygon({
        zIndex: 100,
        center: v.center,
        strokeWeight: 2,
        path,
        fillOpacity: 0.1,
        fillColor: "#44AAFF",
        strokeColor: "#2299FF",
        strokeStyle: "dashed",
        extData: v
      });
    }
  }
  computerdData(passDeviceList) {
    // const { passDeviceList } = this.props;
    this.graphData = [];
    this.links = [];
    this.linesData = [];
    this.data && this.data.forEach(v => {
      let index = this.getFreeIndex();
      v.index = index;
      this.graphData.push({
        value: v.position,
        index: index,
        ...v
      });
    });
    this.passDeviceList = passDeviceList.map(v => {
      const FIND = this.data.find(item => item.cid === v.cid);
      if (!!FIND) {
        v.index = FIND.index;
      }
      return v;
    });
    this.data && this.data.forEach(v => {
      v.list &&
        v.list.forEach(v2 => {
          if (this.graphData.findIndex(v3 => v3.name === v2.name) === -1) {
            this.graphData.push({
              value: v2.position,
              index: this.getFreeIndex(),
              ...v2
            });
          }
          let lw = getLineWidth(v2.count);
          this.linesData.push({
            coords: [v2.position, v.position],
            lineStyle: {
              normal: {
                width: lw,
                color: "orange",
                curveness: 0.2
              }
            }
          });
          this.links.push({
            source: v2.name,
            target: v.name,
            count: v2.count,
            symbol: ["none", "arrow"],
            symbolSize: Math.round(lw * 1.2) + 12,
            lineStyle: {
              normal: {
                width: lw,
                color: "red",
                curveness: 0.2
              }
            }
          });
        });
    });
  }
  getOptions() {
    return createOptions({
      data: this.graphData.filter(v => v.position && v.position[0] && v.position[1]),
      links: this.links,
      center: this.data[0] ? this.data[0].position : [114.305215, 30.592935],
      callback: amap => {
        if (amap && !this.amap) {
          this.amap = amap;
          this.props.init && this.props.init(this);
        }
      }
    });
  }

  markerClick(item) {
    let index = this.data.findIndex(v => v.index === item.index);
    this.setState({ currentIndex: index });
  }

  onClickCard = item => {
    if (!item.position || (!item.position[0] && !item.position[1]) || !item.index) {
      return;
    }
    let index = this.data.findIndex(v => v.index === item.index);
    this.setState({ currentIndex: index });
    this.amap.setZoomAndCenter(17, item.position);
  };

  updateGraph() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.echart.setOption(this.getOptions()), 500);
  }

  getFreeIndex(index = "A") {
    if (this.graphData.findIndex(v => v.index === index) > -1) {
      const nextIndex = this.markerIndex[this.markerIndex.indexOf(index) + 1];
      if (nextIndex) {
        return this.getFreeIndex(nextIndex);
      } else {
        return Math.random()
          .toString(36)
          .substr(1);
      }
    } else {
      return index;
    }
  }

  mapReset = () => {
    this.amap.setFitView([].concat(this.markers, this.placeMarkers, this.areaCircle));
  };

  mapZoom = zoom => {
    this.amap.setZoom(this.amap.getZoom() + zoom);
  };

  ctrlMosueWheel = e => {
    clearTimeout(this.timer);
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      this.timer = setTimeout(() => this.mapZoom(e.deltaY > 0 ? -1 : 1), 100);
    }
  };

  render() {
    const { currentIndex } = this.state;
    const { hasInfo, resetToolsClassName } = this.props;
    let total = 0;
    this.passDeviceList.forEach(v => {
      total += +v.count;
    });
    return (
      <div className="person-flow-map-layout" ref={this.layoutRef}>
        <div className="person-flow-map" ref={this.domRef} />
        <MapResetTools
          className={`${resetToolsClassName} ${hasInfo ? "person-map-reset" : ""}`}
          mapReset={this.mapReset}
          mapZoom={this.mapZoom}
        />
        {hasInfo && (
          <>
            <div className="total">
              总出现次数：<span>{`${total}次`}</span>
            </div>
            <PersonPathInfo
              onClickCard={this.onClickCard}
              currentIndex={currentIndex}
              // data={arr}
              data={this.passDeviceList}
            />
          </>
        )}
      </div>
    );
  }
}

function createOptions({ callback, center, data, links }) {
  return {
    // use amap component
    amap: {
      config: {
        center,
        zoom: 5,
        scrollWheel: false
      },
      initAMap: amap => {
        callback && callback(amap);
      }
    },
    // demo serie showing the capital BEIJING of our PRC :cn:
    series: [
      {
        type: "graph",
        layout: "none",
        coordinateSystem: "amap",
        symbol: "circle",
        symbolSize: 10,
        z: 3,
        edgeLabel: {
          normal: {
            show: true,
            fontSize: 14,
            position: "middle",
            formatter: function(params) {
              return "";
            }
          }
        },
        label: {
          normal: {
            show: true,
            position: "bottom",
            color: "#5e5e5e"
          }
        },
        itemStyle: {
          normal: {
            shadowColor: "none"
          },
          emphasis: {}
        },
        lineStyle: {
          normal: {
            width: 2,
            shadowColor: "none"
          }
        },
        edgeSymbol: ["none", "arrow"],
        edgeSymbolSize: 8,
        data,
        links
      } //,
    ]
  };
}

class PersonPathInfo extends React.Component {
  renderItem = (item, index) => {
    return (
      <div className="path-item">
        {item.index ? (
          <span className="item-index" onClick={() => this.props.onClickCard(item)}>
            {item.index}
          </span>
        ) : (
          <span className="item-icon">
            <IconFont type="icon-S_Bar_Lat-Long_No" />
          </span>
        )}

        <div className="item-text">
          <span className="item-count">
            出现<i>{item.count}</i>次
          </span>
          <span title={item.name} className="item-name">
            {item.name || item.deviceName}
          </span>
          {item.position || item.longitude || item.latitude ? undefined : <div>(无经纬度)</div>}
        </div>
      </div>
    );
  };
  render() {
    const { data, currentIndex } = this.props;
    return (
      <HorizontalScrollLayout
        currentIndex={currentIndex}
        className="person-path-info"
        size={5}
        data={data}
        renderItem={this.renderItem}
      />
    );
  }
}
export default PathSimplifier;
