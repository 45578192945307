import * as _ from 'lodash'
export function queryUserDevice(options) {
  return Promise.all([
    Service.device.queryUserDevicesNew(options ? options : { queryType: 2 }),
    Service.device.queryUserDevicesStatusNew(options ? options : { queryType: 2 }),
    Service.device.queryDeviceLocalRecordAbilityList(options ? options : { queryType: 2 })
  ])
    .then(devices => {
      const [listRes, statusRes, frontEndRes] = devices
      let deviceState = {}
      statusRes.data.split('|').forEach(res => {
        const [id, state] = res.split(',')
        deviceState[id] = `${state}`
      })
      let deviceFrontEnd = {}
      let deviceStorage = {}
      frontEndRes &&
        frontEndRes.data.split('|').forEach(res => {
          const [id, state, storage] = res.split(',')
          deviceFrontEnd[id] = state
          deviceStorage[id] = storage
        })
      return formatDeviceMap(listRes.data.list, listRes.data.other, deviceState, deviceFrontEnd, deviceStorage)
    })
    .catch(() => ({}))
}

export function formatDeviceList(data = [], groupLibs = {}, deviceState = {}, deviceFrontEnd = {}, deviceStorage = {}) {
  return data.map(item => {
    let [
      cid,
      deviceName,
      _,
      deviceType,
      id,
      latitude,
      longitude,
      lygroupIndex,
      manufacturerDeviceId,
      manufacturerDeviceType,
      organizationIds,
      pathId,
      placeId,
      sn,
      boxNum
    ] = item.split('|')
    try {
      organizationIds = JSON.parse(organizationIds).map(v => v.toString())
    } catch (e) {
      organizationIds = []
    }
    try {
      pathId = JSON.parse(pathId)
    } catch (e) {
      pathId = []
    }
    return {
      cid,
      deviceName,
      deviceStatus: deviceState[id],
      deviceType,
      id,
      latitude,
      longitude,
      lygroupId: groupLibs[lygroupIndex],
      manufacturerDeviceId,
      manufacturerDeviceType,
      organizationIds,
      pathId,
      placeId,
      sn,
      boxNum,
      hasFrontEnd: +deviceFrontEnd[id] !== 0,
      hasStorage: +deviceStorage[id] !== 0
    }
  })
}

export function formatDeviceMap(data = [], groupLibs = {}, deviceState = {}, deviceFrontEnd = {}, deviceStorage = {}) {
  const idMap = {}
  const cidMap = {}
  data.forEach(item => {
    let [
      cid,
      deviceName,
      _, //deviceStatus
      deviceType,
      id,
      latitude,
      longitude,
      lygroupIndex,
      manufacturerDeviceId,
      manufacturerDeviceType,
      organizationIds,
      pathId,
      placeId,
      sn,
      boxNum
    ] = item.split('|')
    try {
      organizationIds = JSON.parse(organizationIds).map(v => v.toString())
    } catch (e) {
      organizationIds = []
    }
    try {
      pathId = JSON.parse(pathId)
    } catch (e) {
      pathId = []
    }
    idMap[id] = cidMap[cid] = {
      cid,
      deviceName,
      deviceStatus: deviceState[id],
      deviceType,
      id,
      latitude,
      longitude,
      lygroupId: groupLibs[lygroupIndex],
      manufacturerDeviceId,
      manufacturerDeviceType,
      organizationIds,
      pathId,
      placeId,
      sn,
      boxNum,
      hasFrontEnd: +deviceFrontEnd[id] !== 0,
      hasStorage: +deviceStorage[id] !== 0
    }
  })
  return { idMap, cidMap };
}
