import React from 'react';
import { difference } from 'lodash';

import '../style/trajectory-map.scss';

const { providerMap, mapContext, Trajectory } = LMap;
const ImageView = Loader.loadBaseComponent('ImageView');

@providerMap({
  className: 'trajectory-map-layout'
})
@mapContext.map
class TrajectoryMap extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data ? props.data : [];
    this.trajectory = null;
    this.contentRef = React.createRef();
    this.state = {
      currentIndex: 0
    };
  }
  componentWillReceiveProps(nextProps) {
    const list = difference(nextProps.data, this.data);
    if (list.length > 0) {
      this.data = nextProps.data;
      this.trajectory.setData(this.data);
    }
  }
  componentWillUnmount() {
    this.data = null;
  }
  init = path => {
    this.trajectory = path;
    this.trajectory.setData(this.data);
    const { init } = this.props;
    init && init(path);
  };
  changeIndex = index => {
    this.setState({ currentIndex: index });
    this.trajectory.setNavState('pause');
    this.trajectory.changeIndex(index);
    // this.props.mapMethods.setZoomAndCenter(15,this.data[index].position)
  };
  changeIndexCallback = index => {
    this.setState({ currentIndex: index });
    this.contentRef.current && this.contentRef.current.setScrollTop(index);
    // this.props.mapMethods.setZoomAndCenter(15,current.position)
  };

  render() {
    const { currentIndex } = this.state;
    const { onClickCard, type } = this.props;
    const currentData = this.data[currentIndex];
    return (
      <Trajectory
        init={this.init}
        changeIndexCallback={this.changeIndexCallback}
        content={
          // <CaptureCard className="detail-box-item" relativeIcon="" hoverScale={false} onClick={() => onClickCard && onClickCard(currentData)} {...currentData} />
          <MapContent onClick={() => onClickCard && onClickCard(currentData)} imageUrl={currentData.imgUrl} />
        }
      >
        <TrajectoryContent data={this.data} currentIndex={currentIndex} changeIndex={this.changeIndex} ref={this.contentRef} type={type} />
      </Trajectory>
    );
  }
}

class TrajectoryContent extends React.Component {
  constructor() {
    super();
    this.listRef = React.createRef();
  }
  changeIndex = index => {
    this.props.changeIndex(index);
  };
  setScrollTop = index => {
    if (!this.listRef.current) {
      return;
    }
    this.listRef.current.scrollTop = 158 * index;
  };

  getTitle = (type) => {
    let text = '';
    switch(type) {
      case 'face': 
      text = '人脸';
      break;
      case 'body':
      text = '人体';
      break;
      case 'vehicle':
      text = '机动车';
      break;
      default: 
      text = '人脸';
    }
    return text;
  }

  render() {
    const { data, currentIndex, type } = this.props;
    return (
      <div className="map-content-part">
        <h3>{this.getTitle(type)}轨迹列表</h3>
        <div className="content-layout" ref={this.listRef}>
          {data.map((item, index) => (
            <HorizontalCard {...item} active={currentIndex === index} onClick={() => this.changeIndex(index)} />
          ))}
        </div>
      </div>
    );
  }
}

function HorizontalCard({ ...props }) {
  const { imgUrl, deviceName, captureTime, onClick, active } = props;
  return (
    <div className={`horizontal-item ${active ? 'active' : ''}`} onClick={onClick}>
      <div className="image-box">
        <ImageView src={imgUrl} />
      </div>
      <div className="item-label">
        <span>{deviceName}</span>
        <span>{Utils.formatTimeStamp(captureTime)}</span>
      </div>
    </div>
  );
}

function MapContent({ imageUrl ,onClick}) {
  return (
    <div className="map-image " onClick={onClick}>
      <div className="image-box">
        <ImageView src={imageUrl} />
      </div>
    </div>
  );
}

export default TrajectoryMap;
