const znqjimg = '/resource/image/map/znqj.svg'; // 智能枪机
const qjimg = '/resource/image/map/qj.svg'; // 球机
const zpjimg = '/resource/image/map/zpj.svg'; // 抓拍机
const mjimg = '/resource/image/map/mj.svg'; // 门禁
// const zjimg = '/resource/image/map/zj.svg'; // 闸机
const dbimg = '/resource/image/map/db.svg'; // 单兵
const wifiimg = '/resource/image/map/wifi.svg'; // wifi
const clzpjimg = '/resource/image/map/clzpj.svg'; // 车辆抓拍机




const { qj, znxj, zpj, db, mj, zj, wifi ,clzpj} = Dict.map

/**根据设备类型和状态获取设备展示图标 */
export const getCameraTypeIcon = (type, status) => {
  const onLine = status * 1 === 1;
  let option = {};
  if (znxj.value == type) {
    option.url = znqjimg;
    option.color = !onLine ? '#ccc' : 'rgba(255, 156, 84, 1)';
    option.bgColor = !onLine ? '#ccc' : '#17bc84';
    option.icon = "icon-S_Point_CameraSmart";
  }
  if (qj.value == type) {
    option.url = qjimg;
    option.color = !onLine ? '#ccc' : 'rgba(43, 153, 255, 1)';
    option.bgColor = !onLine ? '#ccc' : '#17bc84';
    option.icon = "icon-S_Point_CameraBall";
  }
  if (zpj.value == type) {
    option.url = zpjimg;
    option.color = !onLine ? '#ccc' : 'rgba(193, 100, 207, 1)';
    option.bgColor = !onLine ? '#ccc' : '#17bc84';
    option.icon = "icon-S_Point_CameraGun";
  }
  if (db.value == type) {
    option.url = dbimg;
    option.color = !onLine ? '#ccc' : 'rgba(255, 156, 84, 1)';
    option.bgColor = !onLine ? '#ccc' : '#17bc84';
    option.icon = "icon-S_Point_Phone";
  }
  if (mj.value.indexOf(type) > -1) {
    option.url = mjimg;
    option.color = !onLine ? '#ccc' : 'rgba(255, 156, 84, 1)';
    option.bgColor = !onLine ? '#ccc' : '#17bc84';
    option.icon = "icon-S_Point_Door";
  }
  // if (zj.value == type) {
  //   option.url = zjimg;
  //   option.color = !onLine ? '#ccc' : 'rgba(255, 156, 84, 1)';
  //   option.bgColor = !onLine ? '#ccc' : '#17bc84';
  //   option.icon = "icon-S_Point_Car";
  // }
  if (wifi.value == type) {
    option.url = wifiimg;
    option.color = !onLine ? '#ccc' : 'rgba(255, 156, 84, 1)';
    option.bgColor = !onLine ? '#ccc' : '#17bc84';
    option.icon = "icon-S_Point_Wifi";
  }
  if (clzpj.value == type) {
    option.url = clzpjimg;
    option.color = !onLine ? '#ccc' : 'rgba(255, 156, 84, 1)';
    option.bgColor = !onLine ? '#ccc' : '#17bc84';
    option.icon = "icon-S_Point_CameraCar";
  }

  return option;
};
