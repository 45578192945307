import React from "react";
import ClusterMap from "./ClusterMap";
import { message } from "antd";
import { inject } from "mobx-react";
import MoveableTimeChoise from "../../../pages/videoSurveillance/components/MoveableTimeChoise";
import _ from "lodash";

const { CommunityPolygon } = LMap;
@inject("device", "menu","user")
class CommunityMap extends React.Component {
  constructor(props) {
    super(props);
    this.communityRef = React.createRef();
    this.timeChoiseRef = React.createRef();
    this.mapViewRef = React.createRef();
    this.getDevicePrefercence();
  }
  
  componentDidMount() {
    const { init } = this.props;
    init && init(this.communityRef);
  }
  
  componentWillUnmount() {
    this.communityRef = null;
  }
  // 获取设备开流偏好设置
  getDevicePrefercence = () => {
    const { device, user } = this.props;
    const userId = user.userInfo.id;
    const storeKey = device.streamKVKey;
    return Service.kvStore
      .getKvStore({ userId, storeKey })
      .then(storeValue => {
        const streamPreference = JSON.parse(storeValue.data.storeValue || "[]");
        return device.setStreamPreference(streamPreference);
      })
      .catch(() => Promise.resolve());
  };
  toggleTimeChoiseContent = (visible = true, clearTime = false) => {
    if (!visible) {
      this.timeChoiseRef.current.setDeviceInfo();
    }
    this.timeChoiseRef.current.setVisible(visible, clearTime);
  };
  clearSelectDevice = () => {
    // this.playerDatas = [];
    this.toggleTimeChoiseContent(false);
    // this.setState({ selectDevice: [], loopModalKey: Math.random() });
  };
  queryRealTimeAddressMulti = deviceList => {
    const { device } = this.props;
    const deviceInfos = deviceList.map(v => {
      let flvStream = device.streamPreference.indexOf(v.cid) !== -1;
      const data = {
        cid: v.cid || v.id,
        deviceName: v.deviceName || v.name,
        deviceType: v.deviceType,
        flvStream
      };
      // ptzTypes.push(
      //   info.extJson &&
      //     info.extJson.cameraInfo &&
      //     info.extJson.cameraInfo.type
      // );
      return data;
    });
    return Service.video.queryRealTimeAddressMulti(deviceInfos);
  };
  handleHistoryVideo = async item => {
    if (!item.storageLimit) {
      const storageLimit = await this.getDeviceStorageLimit(item);
      item.storageLimit = storageLimit;
    }
    this.updateTimeChoiseDeviceInfo(item);
    this.toggleTimeChoiseContent();
  };
  updateTimeChoiseDeviceInfo = item => {
    this.timeChoiseRef.current.setDeviceInfo(_.cloneDeep(item));
  };
  // 查询设备存储周期
  getDeviceStorageLimit = item => {
    return Service.device
      .queryDeviceInfoByCid(item.cid)
      .then(result => {
        let storageLimit;
        try {
          storageLimit = +result.data.extJson.cameraInfo.storage.video || 7;
        } catch {
          storageLimit = 7;
        }
        return storageLimit;
      })
      .catch(e => 7);
  };
  jumpCommunity = id => {
    this.communityRef.current && this.communityRef.current.jumpCommunity(id);
  };
  submitHistoryTime = options => {
    const MAX_HISTORY_GAP = 7;
    if (options.startTime - options.endTime > 3600 * 24 * MAX_HISTORY_GAP) {
      return message.error(`录像视频查看不能超过${MAX_HISTORY_GAP}天`);
    }
    options.isLiving = false;
    const { deviceInfo } = this.timeChoiseRef.current.state;
    this.onSelectDevice(deviceInfo, options);
  };
  onSelectDevice = (item, options = { isLiving: true }) => {
    this.mapViewRef.current.wrappedInstance.markerClick(item, options);
  };
  queryHistoryAddress = ({ cid, startTime, endTime, deviceName }) => {
    const data = {
      cid,
      deviceName,
      startTime,
      endTime
    };
    return Service.video.queryHistoryAddress(data);
  };
  render() {
    const { children, points = [], villages = [], openModal, customInfoWindow, closeCustomInfoWindow } = this.props;
    return (
      <ClusterMap
        points={points}
        closeVideo={this.clearSelectDevice}
        toggleTimeChoiseContent={this.toggleTimeChoiseContent}
        queryRealTimeAddressMulti={this.queryRealTimeAddressMulti}
        handleHistoryVideo={this.handleHistoryVideo}
        ref={this.mapViewRef}
        queryHistoryAddress={this.queryHistoryAddress}
        hasDownload={false}
        hasScreenshot={false}
        communityLayer={true}
        hoverContent={<div />}
        customInfoWindow={customInfoWindow}
        closeCustomInfoWindow={closeCustomInfoWindow}
      >
        <MoveableTimeChoise
          ref={this.timeChoiseRef}
          onOk={this.submitHistoryTime}
          toggleTimeChoiseContent={this.toggleTimeChoiseContent}
        />
        <CommunityPolygon villages={villages} ref={this.communityRef} openModal={openModal}/>
        {children ? children : null}
      </ClusterMap>
    );
  }
}

export default CommunityMap;
